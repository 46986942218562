import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import routed from "@/plugins/router";
var ls = new SecureLS({ isCompression: false });

const storeData = createStore({
  state() {
    return {
      loginUser: null,
    };
  },
  getters: {
    getTokenValue: (state) => state.loginUser.token,
    isAuthentication: (state) => state.loginUser != null,
    getLoginUser: (state) => state.loginUser,   
  },
  mutations: {
    loginUser(state, data) {
      console.log('Geldi :>> ', data);
      state.tokenValue = data.token;
      state.loginUser = data;
      routed.push({name:"Dashboard"})
    },
    logoutUser(state) {
      state.tokenValue = undefined;
      state.loginUser = undefined;
      routed.push({ name: "Login" });
    },
    changeLoading(state, status) {      
      state.loadingState = status;      
    },
  },
  computed: {},
  plugins: [
    createPersistedState({
      key: "kultursanatakademiadm",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

export default storeData;
