// import { quillEditor } from 'vue3-quill'

// export default quillEditor;


import { quillEditor } from 'vue3-quill'

// import customQuillModule from 'customQuillModule'
// Quill.register('modules/formula', customQuillModule)

export default quillEditor;