import { createApp } from 'vue'

import store from '@/plugins/store/index';
import route from '@/plugins/router/index';
import axios from '@/plugins/axios';
import bootstrap from 'bootstrap'
import quill from '@/plugins/quill/index';
import Vue3EasyDataTable from 'vue3-easy-data-table'; //https://codesandbox.io/s/item-slot-65tc9v?file=/src/App.vue:1586-2060




import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue3-toastify/dist/index.css';
import '@vueform/multiselect/themes/default.css'
import '@/assets/css/style.css'
import 'vue3-easy-data-table/dist/style.css';



const app = createApp(App);

app.use(store);
app.use(route);
app.use(axios);
app.use(bootstrap);
app.use(quill);

app.component('EasyDataTable', Vue3EasyDataTable);





app.mount('#app');
