import { createRouter, createWebHashHistory } from "vue-router";
import stored from "@/plugins/store";
const routes = [
  {
    name: "Dashboard",
    path: "/",
    component: () => import("@/views/DashboardPage"),
    props: true,
  },
  {
    name: "Login",
    path: "/login",
    component: () => import("@/views/LoginPage"),
    props: true,
  },
  {
    name: "Profilim",
    path: "/profilim",
    component: () => import("@/views/ProfilimPage"),
    props: true,
  },
  {
    name: "Kullanicilar",
    path: "/kullanicilar",
    component: () => import("@/views/KullanicilarPage"),
    props: true,
  },
  {
    name: "Egitmenler",
    path: "/egitmenler",
    component: () => import("@/views/EgitmenlerPage"),
    props: true,
  },
  {
    name: "Juriler",
    path: "/juriler",
    component: () => import("@/views/JurilerPage"),
    props: true,
  },
  {
    name: "Kategoriler",
    path: "/kategoriler",
    component: () => import("@/views/KategorilerPage"),
    props: true,
  },
  {
    name: "Bloglar",
    path: "/bloglar",
    component: () => import("@/views/BloglarPage"),
    props: true,
  },
  {
    name: "Paketler",
    path: "/paketler",
    component: () => import("@/views/PaketlerPage"),
    props: true,
  },
  {
    name: "Egitimler",
    path: "/egitimler",
    component: () => import("@/views/EgitimlerPage"),
    props: true,
  },
  {
    name: "Ogrenciler",
    path: "/ogrenciler",
    component: () => import("@/views/OgrencilerPage"),
    props: true,
  },
  {
    name: "Basvurular",
    path: "/basvurular",
    component: () => import("@/views/BasvuruPage"),
    props: true,
  },
  
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {    
  const isAuth = stored.getters.isAuthentication;  
  if (isAuth == false && to.fullPath!="/login") next({ name: "Login" });
  else next();
});

export default router;
