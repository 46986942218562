<template>
  <app-header v-show="this.$store.getters.isAuthentication" />
  <router-view></router-view>
</template>

<script>
import AppHeader from './components/appHeader.vue';
export default {
  name: "App",
  components: {AppHeader},
};
</script>

<style>
@import '~bootstrap';
@import '~bootstrap-vue';
html,body{
  font-size: 12px !important;
}
/* @import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-vue/src/index.scss'; */
</style>
