<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link :to="{ name: 'Dashboard' }">
        <img
          :src="logoPath"
          style="height: 30px"
          :style="!isMobile() ? 'width:150px' : 'width:120px'"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse ms-5" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Tanımlar
            </a>

            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <router-link :to="{name:'Kullanicilar'}" class="dropdown-item">Kullanicilar</router-link>
              </li>
              <li>
                <router-link :to="{name:'Egitmenler'}" class="dropdown-item">Eğitmenler</router-link>                
              </li>
              <li>
                <router-link :to="{name:'Juriler'}" class="dropdown-item">Jüriler</router-link>                
                
              </li>
              <li>
                <router-link :to="{name:'Kategoriler'}" class="dropdown-item">Kategoriler</router-link>                
                
              </li>              
              <li>
                <router-link :to="{name:'Bloglar'}" class="dropdown-item">Bloglar</router-link>                
              </li>
              <li>
                <router-link :to="{name:'Basvurular'}" class="dropdown-item">Başvurular</router-link>                
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <router-link :to="{name:'Paketler'}" class="nav-link">Paketler</router-link>                
          </li>
          <li class="nav-item">
            <router-link :to="{name:'Egitimler'}" class="nav-link">Eğitimler</router-link>                
          </li>
          <li class="nav-item">
            <router-link :to="{name:'Ogrenciler'}" class="nav-link">Öğrenciler</router-link>                
          </li>

          
        </ul>
      </div>

      <!-- This is what is not working well -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >{{ !isMobile() ? 
            (this.$store.getters.getLoginUser?.name +' '+this.$store.getters.getLoginUser?.surname) : 
            (this.$store.getters.getLoginUser?.name.substring(0,1)+'.'+this.$store.getters.getLoginUser?.surname.substring(0,1)+'.') }}
          </a>

          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown"
            style="position: absolute"
          >
            
            <router-link :to="{name:'Profilim'}" class="dropdown-item dropdown">Profilim</router-link>
            <a class="dropdown-item" href="javascript:void(0)" @click="this.$store.commit('logoutUser')"> Çıkış Yap </a>            
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logoPath: require("@/assets/logo.png"),
    };
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style></style>
