
import axios from "axios";

let apiUrlFull = "https://api.kultursanatakademi.com";
//let apiUrlFull = "https://localhost:5002";



var instance = axios.create({
  baseURL: apiUrlFull ,  
  
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log("axiosError :>> " + error);
  }
);

export default {
  install: function (Vue) {
    Vue.config.globalProperties.$axios = instance;
  },
};
